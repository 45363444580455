<template>
  <div>
    <b-table
        ref="note-table"
        striped
        hover
        responsive
        v-bind:items="notes"
        v-bind:fields="fields"
        v-bind:sort-by.sync="sort.by"
        v-bind:sort-desc.sync="sort.desc"
        v-bind:sort-direction="sort.direction"
    >
      <template #cell(branchId)="data">
        {{ getBranchName(data.value) }}
      </template>
      <template #cell(client)="data">
        <b-link @click="onClientSelect(data.value.id)">{{ data.value.name }}</b-link>
      </template>
      <template #cell(type)="data">
        {{ getTypes[data.value].name }}
      </template>
    </b-table>

    <div class="text-center w-100">
      <div class="d-inline-block">
        <b-pagination
            v-model="page.currentPage"
            :total-rows="page.total"
            :per-page="page.amount"
            first-number
            last-number
            @input="refresh"
        />
      </div>
      <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'clientNotes-table',
  data() {
    return {
      notes: [],
      fields: [],
      sort: {
        by: '',
        desc: false,
        direction: 'asc'
      },
      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },
  watch: {
    ['page.amount']() {
      if (this.page.amount !== this.amount) {
        this.$emit('update:amount', this.page.amount)
      }

    },
    amount() {
      if (this.page.amount === this.amount) {
        return
      }
      this.page.amount = this.amount
      this.refresh()
    },
  },
  created() {
    console.log('clientNotes-table created')
    let $this = this
    this.getData(true)
    this.setFields()
    this.refresh()
    this.$root.$on('crm::note-table::refresh', $this.listeners.refresh = () => {
      $this.refresh()
    })
  },
  beforeDestroy() {
    this.$off('crm::note-table::refresh', this.listeners.refresh)
  },
  computed: {
    ...mapGetters('data', ['getBranchName']),
    ...mapGetters('notes', ['getTypes']),
  },
  methods: {
    ...mapActions('notes', ['getNotes', 'getData']),
    ...mapActions('clients', ['getClients']),
    setFields() {
      let $this = this
      this.fields = [
        {
          key: 'creationtime',
          label: $this.msg('creationtime'),
          sortable: true
        },
        {
          key: 'branchId',
          label: $this.msg('branch'),
          sortable: true
        },
        {
          key: 'docnum',
          label: $this.msg('Document Number'),
          sortable: false
        },
        {
          key: 'client',
          label: $this.msg('Name'),
          sortable: false
        },
        {
          key: 'email',
          label: $this.msg('Email'),
          sortable: false
        },
        {
          key: 'type',
          label: $this.msg('type'),
          sortable: false
        },
        {
          key: 'text',
          label: $this.msg('note'),
          sortable: false
        },
      ]
    },
    refresh() {
      let $this = this
      this.getNotes({
        page: $this.page.currentPage,
        amountPerPage: $this.page.amount,
        filter: $this.filter
      })
          .then($this.onNotesRetrieve)
    },
    onNotesRetrieve(noteData) {
      this.page = {
        amount: noteData['amountPerPage'],
        total: noteData['total'],
        currentPage: noteData['page']
      }
      this.retrieveClients(noteData.notes)
    },
    retrieveClients(notes) {
      let $this = this
      let ids = notes.map((note) => note.clientId)
      this.getClients({
        page: 1,
        amountPerPage: -1,
        filter: { ids }
      })
          .then((clientData) => $this.saveNotes(clientData, notes))
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select',clientId)
    },
    saveNotes(clientData, notes) {
      let $this = this
      let clients = clientData.clients.toObject('id')
      this.notes = notes.filter(n => clients[n.clientId] != null)
          .map(n => $this.mergeNotesAndClient(n, clients[n.clientId]))
      this.$refs['note-table'].refresh()
    },
    mergeNotesAndClient(note, client) {
      let mergedNote = { ...note }
      mergedNote['client'] = {id:client.id,name:[client.firstname, client.lastname].mergeNames()}
      mergedNote['email'] = client.email
      mergedNote['docnum'] = client.docNum
      mergedNote['branchId'] = client.branchId
      return mergedNote
    }
  }
}
</script>

<style scoped>

</style>
