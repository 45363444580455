<template>
  <div>
    <b-card-actions
        :title="msg('Filters')"
        @refresh="refresh()"
        actionCollapse
        actionRefresh
    >
      <b-row>
        <!--Creation Time-->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ msg('Creation Time') }}</label>
          <VueCtkDateTimePicker :button-now-translation="msg('Now')" range no-shortcuts
                                v-model="creationDate"
                                :locale="currentFormattedLang" :only-date="true"
                                format="YYYY-MM-DD" formatted="ll" no-header
          >
            <b-form-input
                :value="rangeToStr(creationDate)"
                class="d-inline-block mr-1"
            />
          </VueCtkDateTimePicker>
        </b-col>
        <!-- Field: Users -->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-form-group :label="msg('Name')" label-for="rep-user">
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                multiple
                v-model="filter.usersIds"
                :options="allUsers"
                class="w-100"
                label="fullName"
                :reduce="(us) => us.id"
                clearable
            />
          </b-form-group>
        </b-col>
        <!-- Field: Types -->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-form-group :label="msg('Types')" label-for="rep-user">
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                multiple
                v-model="filter.types"
                :options="allTypes"
                class="w-100"
                label="name"
                :reduce="(us) => us.id"
                clearable
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card no-body>
      <b-row no-gutters class="p-1">

        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>{{ msg('Show') }}</label>
          <v-select
              v-model="amount"
              :dir="isRTL ? 'rtl' : 'ltr'"
              :options="[10, 25, 50, 100]"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ msg('entries') }}</label>
        </b-col>

        <!-- Search -->
        <b-col
            cols="12"
            md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" class="btn-icon" v-b-tooltip :title="msg('Download')"  @click="downloadReport">
              <i class="far fa-file-excel"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <client-notes-table v-bind:filter="filter" v-bind:amount.sync="amount" />
    </b-card>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters } from 'vuex'
import ClientNotesTable from '@/views/client-notes/clientNotes-table'

export default {
  components: {
    ClientNotesTable,
    BCardActions
  },
  name: 'clientNotes',
  data: () => ({
    filter: {fromDate:null,untilDate:null},
    amount:10,
    items:[],
    excelUrl:""
  }),
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refreshURL()
      }
    },
  },
  created() {
    console.log("clientNotes created")
    this.refreshURL();
  },
  computed: {
    ...mapGetters('data', ['getUserFullName', 'getAllUsers', 'getUser']),
    ...mapGetters('notes',['getTypes']),
    allTypes(){
      return Object.values(this.getTypes)
    },
    allUsers() {
      let $this = this
      return this.getAllUsers.map(user => ({
        id: user.id,
        fullName: $this.getUserFullName(user.id),
      }))
    },
    creationDate:{
      get(){
        return {start:this.filter.fromDate,end:this.filter.untilDate}
      },
      set(v){
        this.filter.fromDate = v.start;
        this.filter.untilDate = v.end;
      }
    }
  },
  methods: {
    ...mapActions('notes',['downloadReport','getExcelUrl']),
    refresh(){
      this.refreshURL()
      this.$root.$emit("crm::note-table::refresh");
    },
    refreshURL(){
      let $this = this;
      this.getExcelUrl(this.filter).then((url)=>$this.excelUrl = url);
    },
    exportToExcel(){
      this.downloadReport(this.filter)
    },
    downloadReport(){
      //window.location = this.excelUrl;
      window.open(this.excelUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    },
    rangeToStr(dateRange){
      if(dateRange.start == null)
        return "";
      if(dateRange.end == null)
        return this.$moment(dateRange.start,'YYYY-MM-DD').format('DD/MM/YYYY');
      return this.$moment(dateRange.start,'YYYY-MM-DD').format('DD/MM/YYYY') + " - " + this.$moment(dateRange.end,'YYYY-MM-DD').format('DD/MM/YYYY');
    }

  }
}
</script>

<style scoped>

</style>
